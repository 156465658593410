<template>
     <div >
        
    </div>

</template>

<script>

export default {
	components: {
    },
	data() {
		return {}
	},
	productService: null,
	eventService: null,
	created() {
        window.location.href="https://promo.sabeeinsurance.com/";
	},
	mounted() {
        window.location.href="https://promo.sabeeinsurance.com/";
	},
	methods: {
	},
	computed: {
    }

}
</script>
